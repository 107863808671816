import styles from './charging-station-table.module.scss';
import globalStyles from '../../../global-styles.module.scss';

import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStores } from 'src/store';

import { Loader } from '../../../components';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import { Row } from './components/row';
import ClearIcon from '@mui/icons-material/Clear';

import IconButton from '@mui/material/IconButton';
import { ChangeAvailability } from './components/change-availability';
import { BatchAction, ChargingStationConnectionStatus } from 'src/store/model';

type Filters = 'location_name' | 'owner' | 'installer' | 'charging_station_uid';
const prev: string[] = [];

export const ChargingStationTable = observer(() => {
  const {
    chargingStationStore,
    actionStore,
    locationStore,
  } = useStores();

  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [loadingBatch, setLoadingBatch] = React.useState(false);

  const search = useCallback(
    (next?: string) => {
      chargingStationStore.getChargingStations(
        chargingStationStore.searchFilter,
        chargingStationStore.searchString,
        chargingStationStore.rowsPerPage,
        next
      );
    },[chargingStationStore]
  );
  
  const handleChangePage = useCallback((
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {

    let next = '';
    if (newPage <= chargingStationStore.page || chargingStationStore.persistedPagination) {
      next = chargingStationStore.previousNext[newPage] ?? '';
    } else {

      chargingStationStore.setPersistedPagination(false);

      next = chargingStationStore.chargingStationSearchItemsNext ?? '';
      prev[newPage] = next;
      chargingStationStore.setPreviousNext(prev);
    }

    chargingStationStore.getChargingStations(
      chargingStationStore.searchFilter,
      chargingStationStore.searchString,
      chargingStationStore.rowsPerPage,
      next
    );

    chargingStationStore.setPage(newPage);

  }, [chargingStationStore]);

  useEffect(() => {
    //handleChangePage(null, 0);
    handleChangePage(null, chargingStationStore.page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rowsPerPage = parseInt(event.target.value, 10);

    chargingStationStore.getChargingStations(
      chargingStationStore.searchFilter,
      chargingStationStore.searchString,
      rowsPerPage,
      chargingStationStore.previousNext[chargingStationStore.page]
    );

    chargingStationStore.setRowsPerPage(rowsPerPage);
    chargingStationStore.setPage(0);
  };

  const handleChange = (event: any) => {
    chargingStationStore.setSearchFilter(event.target.value as Filters);

    chargingStationStore.getChargingStations(
      event.target.value,
      chargingStationStore.searchString,
      chargingStationStore.rowsPerPage
    );
  };

  const handleSearch = useCallback(
    (search: string) => {
      chargingStationStore.setPage(0);
      chargingStationStore.getChargingStations(
        chargingStationStore.searchFilter,
        search,
        chargingStationStore.rowsPerPage
      );

      chargingStationStore.setPreviousNext([]);
    },
    [chargingStationStore]
  );

  const debounceTimer = useRef<any>();
  const handleSearchDebounced = useCallback(
    (search: string) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
      debounceTimer.current = setTimeout(() => handleSearch(search), 600);
    },
    [handleSearch]
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = chargingStationStore.chargingStationSearchResult
        .filter(
          (item) =>
            item.charging_station?.status?.connection ===
            ChargingStationConnectionStatus.connected
        )
        .map((n) => n.charging_station?.id ?? '');
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeAvailability = useCallback(
    (disable: boolean) => {
      setLoadingBatch(true);
      const batchChange = selected.map((id) => {
        return {
          charging_station_id: id,
          action: 'ChangeAvailability',
          payload: {
            connector_id: 0,
            type: disable ? 'Inoperative' : 'Operative'
          }
        } as BatchAction;
      });

      actionStore.batchAction(batchChange).then(() => handleSearch(chargingStationStore.searchString)).finally(() => {
        setSelected([]);
        setLoadingBatch(false);
      });

    },
    [actionStore, selected, handleSearch, chargingStationStore]
  );

  const select = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = useCallback(
    (id: string) => selected.indexOf(id) !== -1,
    [selected]
  );

  const reloadChargers = () => {
    search(
      chargingStationStore.previousNext[chargingStationStore.page]
    );
  };

  return (
    <div>
      <div className={`${globalStyles.container} ${styles.filterActions}`}>
        <div style={{ position: 'relative', display: 'flex' }}>
          <input
            className={styles.searchField}
            value={chargingStationStore.searchString}
            onChange={(e) => {
              chargingStationStore.setSearchString(e.target.value);
              handleSearchDebounced(e.target.value);
            }}
          />
          <IconButton
            disabled={chargingStationStore.searchString.length === 0}
            onClick={() => {
              chargingStationStore.setSearchString('');
              handleSearch('');
            }}
            style={{ position: 'absolute', top: 0, right: 20 }}
            aria-label="close"
          >
            {chargingStationStore.searchString.length > 0 && <ClearIcon style={{ marginTop: '5px' }} fontSize="inherit" />}
          </IconButton>
        </div>

        <FormControl variant="standard">
          <InputLabel id="select-helper-label">Search by:</InputLabel>
          <Select
            labelId="select-label"
            label="Search by:"
            id="select"
            value={chargingStationStore.searchFilter}
            onChange={handleChange}
          >
            <MenuItem value="location_name">Location</MenuItem>
            <MenuItem value="charging_station_uid">Serial number</MenuItem>
            <MenuItem value="owner">Owner</MenuItem>
            <MenuItem value="installer">Installer</MenuItem>
          </Select>
        </FormControl>

        <Button
          style={{ width: 150 }}
          size="small"
          color="primary"
          onClick={reloadChargers}
        >
          Refresh list
        </Button>

      </div>

      {chargingStationStore.isLoading ? (
        <div className={styles.loader}>
          <Loader />
        </div>
      ) : (
        <TableContainer
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          component={Paper}
          className={`${globalStyles.container} ${styles.chargingStationTable}`}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                      chargingStationStore.chargingStationSearchResult.length
                    }
                    checked={
                      chargingStationStore.chargingStationSearchResult.length >
                      0 &&
                      selected.length ===
                      chargingStationStore.chargingStationSearchResult.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all'
                    }}
                  />
                </TableCell>
                <TableCell />
                <TableCell>Device</TableCell>
                <TableCell align="left">FW</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Location</TableCell>
                <TableCell align="center">Owner(s)</TableCell>
                <TableCell align="center">Installer(s)</TableCell>
                <TableCell align="left">Serial number</TableCell>
                {/* <TableCell align="center">Group master</TableCell> */}
                <TableCell align="center">Availability</TableCell>
                <TableCell align="center">Connection</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chargingStationStore.chargingStationSearchResult.map((row) => {
                return (
                <Row
                  key={row?.charging_station?.id}
                  chargingStationSearchItem={row}
                  connectionGroups={
                    []
                  }
                  setCurrentChargingStationId={(id) =>
                    chargingStationStore.setCurrentChargingStation(id)
                  }
                  getChargingStationSearchItemFromId={(id) =>
                    chargingStationStore.chargingStationSearchResult?.find(
                      (cs) => cs.charging_station?.id === id
                    )
                  }
                  getLocationNameFromId={(id) =>
                    locationStore.locations.find((loc) => loc.id === id)?.name
                  }
                  persistPagination={() =>
                    chargingStationStore.setPersistedPagination(true)
                  }
                  isItemSelected={isSelected(row?.charging_station?.id ?? '')}
                  select={select}
                  navigate={navigate}
                />
              )}
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, 250]}
            component="div"
            count={chargingStationStore.chargingStationSearchItemsCount}
            page={chargingStationStore.page}
            onPageChange={handleChangePage}
            rowsPerPage={chargingStationStore.rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
      <ChangeAvailability
        rowsSelected={selected.length}
        loading={loadingBatch}
        enable={() => handleChangeAvailability(false)}
        disable={() => handleChangeAvailability(true)}
      ></ChangeAvailability>
    </div>
  );
});
