import styles from './field-factory.module.scss';

import {
  Autocomplete,
  Checkbox,
  Chip,
  IconButton,
  TextField,
  MenuItem,
  Typography
} from '@mui/material';
import React, { useCallback } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister
} from 'react-hook-form';
import {
  ChargingStationConfigurationStatus,
  ChargingStationConfigurationVariable,
  JsonSchemaProperty
} from 'src/store/model';
import InfoIcon from '@mui/icons-material/Info';
import { BasicPopover } from 'src/components';
import RestoreIcon from '@mui/icons-material/Restore';

type Props = {
  variableKey: string;
  currentConfiguration: ChargingStationConfigurationVariable | undefined;
  schemaItem: JsonSchemaProperty;
  watchedValue: any;
  control: Control<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  register: UseFormRegister<FieldValues>;
  status?: ChargingStationConfigurationStatus;
  style?: any;
  resetValue: () => void;
  disabled: boolean;
  options?: string[];
};

export const FieldFactory = ({
  control,
  variableKey,
  currentConfiguration,
  schemaItem,
  watchedValue,
  register,
  status,
  resetValue,
  disabled,
  options
}: Props) => {
  const isDefaultValue = useCallback(() => {
    if (watchedValue || currentConfiguration?.value) {
      return (
        (watchedValue || currentConfiguration?.value) ==
        schemaItem.properties.value.default
      );
    } else {
      return true;
    }
  }, [
    currentConfiguration?.value,
    schemaItem.properties.value.default,
    watchedValue
  ]);

  const value =
    watchedValue ??
    currentConfiguration?.value ??
    schemaItem.properties?.value?.default ??
    '';

  return (
    <div className={styles.fieldContainer}>
      <div className={styles.fieldEditContiner}>
        {(() => {

          if (schemaItem.properties?.value?.enum) {
            return (

              <TextField
                variant="standard"
                className={styles.fieldInput}
                label={variableKey}
                value={value || ''}
                select
                disabled={disabled}
                {...register(variableKey)}
              >
                {schemaItem.properties?.value?.enum.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            );
          }

          switch (schemaItem?.properties?.value.type) {
            case 'array': // TBD: until we know how to represent this in UI. Uneditable string
              return (
                <TextField
                  type="text"
                  className={styles.fieldInput}
                  variant="standard"
                  label={variableKey}
                  defaultValue={value}
                  disabled={disabled}
                  {...register(variableKey)}
                />
              );
            case 'string':
              return options?.length ? (
                <Autocomplete
                  id="csms-url"
                  disabled={disabled}
                  defaultValue={value}
                  style={{ flexGrow: 1 }}
                  freeSolo
                  options={options}
                  renderInput={(params) => (
                    <TextField
                      type="text"
                      className={styles.fieldInput}
                      variant="standard"
                      key={params.id}
                      {...params}
                      label={variableKey}
                      defaultValue={value}
                      disabled={disabled}
                      {...register(variableKey)}
                    />
                  )}
                />
              ) : (
                <TextField
                  type="text"
                  className={styles.fieldInput}
                  variant="standard"
                  label={variableKey}
                  defaultValue={value}
                  disabled={disabled}
                  {...register(variableKey)}
                />
              );
            case 'boolean':
              return (
                <div className={styles.booleanField}>
                  <Controller
                    name={variableKey}
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        color="primary"
                        disabled={disabled}
                        // defaultChecked={!!value}
                        checked={!!value}
                        {...field}
                      />
                    )}
                  />
                  <span
                    className={`${styles.booleanCustomLabel} ${disabled ? styles.disabled : undefined
                      }`}
                  >
                    {variableKey}
                  </span>
                </div>
              );
            case 'integer':
            case 'number':
              return (
                <TextField
                  type="number"
                  className={styles.fieldInput}
                  variant="standard"
                  label={variableKey}
                  defaultValue={value}
                  disabled={disabled}
                  {...register(variableKey)}
                />
              );
            default:
              return null;
          }
        })()}

        <BasicPopover
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            marginLeft: '1rem'
          }}
          buttonContent={
            <IconButton onClick={() => null} aria-label="info">
              <InfoIcon color="action" />
            </IconButton>
          }
          popOverContent={
            <Typography
              style={{
                margin: '5px 20px'
              }}
            >
              {schemaItem.description}
            </Typography>
          }
        />

        {schemaItem.mutability !== 'ReadOnly' && !isDefaultValue() && (
          <BasicPopover
            style={{
              marginTop: 'auto',
              marginBottom: 'auto',
              marginRight: '-2.5rem'
            }}
            buttonContent={
              <IconButton onClick={resetValue} aria-label="restore">
                <RestoreIcon color="action" />
              </IconButton>
            }
            popOverContent={
              <Typography
                style={{
                  margin: '5px 20px'
                }}
              >
                Restore default value
              </Typography>
            }
          />
        )}
      </div>

      {status !== ChargingStationConfigurationStatus.accepted && !!status && (
        <Chip
          style={{ maxWidth: '10rem', marginTop: '1rem' }}
          label={(() => {
            switch (status) {
              case ChargingStationConfigurationStatus.not_supported:
                return 'Not supported';
              case ChargingStationConfigurationStatus.reboot_required:
                return 'Reboot required';
              case ChargingStationConfigurationStatus.rejected:
                return 'Rejected';
              default:
                return status;
            }
          })()}
          color="primary"
        />
      )}
    </div>
  );
};
