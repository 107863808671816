export default {
  translation: {
    'uk': 'English (UK)',
    'en': 'English (UK)',
    'fi': 'Suomi',
    'se': 'Svenska',
    'Name': 'Nimi',
    'Email': 'Sähköposti',
    'Password': 'Salasana',
    'Login': 'Kirjaudu',
    'Language same as region': 'Kieli sama kuin alueella',
    'Rename charging station': 'Nimeä lähtöpaikka uudelleen',
    'Vendor': 'Valmistaja',
    'Model': 'Malli',
    'Serial code': 'Sarjakoodi',
    'Current firmware': 'Nykyinen firmware',
    'Custom firmware URL': 'Oma firmware-URL',
    'Change firmware': 'Vaihda firmware',
    'Remove all reservations': 'Poista kaikki varaukset',
    'Reboot device': 'Käynnistä laite uudelleen',
    'Flash LED': 'Flash LED',
    'Select firmware version:': 'Valitse laiteohjelmistoversio:',
    'Official firmware version:': 'Virallinen laiteohjelmistoversio:',
    'Unofficial firmware version:': 'Epävirallinen laiteohjelmistoversio:',
    'Documentation': 'Dokumentaatio',
    'Product information': 'Tuotetiedot',
    'Support': 'Tuki',
    'Download GARO Connect': 'Lataa GARO Connect',
    'GET IT ON': 'LATAA',
    'Manual': 'Käsikirja',
    'Quick Guide': 'Pikaopas',
    'Polemount manual': 'Pylväskiinnike',
    'Stand manual': 'Jalusta',
    'Installation kit': 'Kytkentäsarja jalustaan',
    'Using Proxy': 'Käyttää proxyä',
    'Online': 'Verkossa',
    'Offline': 'Offline-tilassa',
    'Generating logfile': 'Lokitiedoston luominen',
    'Generate logfile': 'Luo lokitiedosto',
    'Currently this portal is for service tecnicians only.': 'Tällä hetkellä tämä portaali on tarkoitettu vain huoltoteknikoille.',
    'Restart software': 'Käynnistä ohjelmisto uudelleen',
    'Theft block status:': 'Varkauslohkon tila:',
    'App Store': 'App Storesta',
    'Google Play': 'Google Playsta',
    'Current schedule': 'Nykyinen aikataulu',
    'Edited schedule': 'Muokattu aikataulu',
    'Monday': 'Maanantai',
    'Tuesday': 'Tiistai',
    'Wednesday': 'Keskiviikko',
    'Thursday': 'Torstai',
    'Friday': 'Perjantai',
    'Saturday': 'Lauantai',
    'Sunday': 'Sunnuntai',
    'Weekly': 'Viikoittain',
    'One time': 'Kerran',
    'Delete event': 'Poista tapahtuma',
    'Delete description': 'Haluatko poistaa aikataulutapahtuman pysyvästi?',
    'No': 'Ei',
    'Yes': 'Kyllä',
    'Start time': 'Aloitusaika',
    'Stop time': 'Lopetusaika',
    'Free of charge': 'Ilmainen',
    'Sync configuration always on boot': 'Synkronoi asetukset aina käynnistyksen yhteydessä',
    'Ignore unknown keys on sync': 'Ohita tuntemattomat avaimet synkronoinnissa',
    'Update firmware automatically': 'Päivitä laiteohjelmisto automaattisesti',
    'Sync configuration as best effort': 'Synkronoi asetukset parhaan kyvyn mukaan',
    'Store warning': 'Varoitus: Arvoa ei tallenneta CSMS:ään ja se saattaa siten ylikirjoittua laitteen seuraavassa käynnistyksessä',
    'Store in CSMS': 'Tallenna CSMS:ään',
    'Key': 'Avain',
    'Value': 'Arvo',
    'Set Parameter Value': 'Aseta parametrin arvo',
    'Get Parameter Value': 'Hae parametrin arvo',
    'Command': 'Komento',
    'Add command': 'Lisää komento',
    'Execute Command': 'Suorita komento',
    'Received data': 'Vastaanotettu data',
    'Snackbar copy message': 'Kopioitu leikepöydälle',
    'Tooltip copy message': 'Kopioitu leikepöydälle',
  },
};