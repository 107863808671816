
import { ApiResponse } from 'src/core';

import { CloudApi } from './cloud-api';

const FW_PACKAGES_RESOURCE_PATH = 'firmware-packages';

export class FirmwarePackagesApi extends CloudApi {
  async getAvailableFirmwareVersions(
    vendor: string,
    model: string,
    minimum_version: string | undefined | null,
    with_secrets: boolean,
  ): Promise<ApiResponse> {
    const order = 'Descending';
    const queryString = CloudApi.objToQueryString({
      vendor,
      model,
      order,
      minimum_version,
      with_secrets,
    });
    console.log('queryString>>', queryString);
    return await this.GET(`${FW_PACKAGES_RESOURCE_PATH}${queryString}`);
  }
}
