import styles from './charging-station-configuration.module.scss';

import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  Typography,
  IconButton
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Loader } from 'src/components';
import { useStores } from 'src/store';
import {
  ChargingStationConfigurationStatus,
  GARO_CSMS_URL_KEY
} from 'src/store/model';

import { ChargingStationStatusHeader } from './charging-station-status-header';
import globalStyles from '../../../../global-styles.module.scss';
import { FieldFactory } from './field-factory';

type Props = {
  chargingStationName: string;
  locationName: string;
  restart: () => void;
  reboot: () => void;
};

type ConfigStatus = {
  key: string;
  status: ChargingStationConfigurationStatus;
};

export const ChargingStationConfiguration = observer(
  ({ restart, reboot }: Props) => {
    const { chargingStationConfigurationsStore } = useStores();
    const [edit, setEdit] = useState(false);

    const {
      defaultConfigurationForCurrentChargingStation,
      currentChargingStationConfigurationVariables
    } = chargingStationConfigurationsStore;

    const {
      control,
      handleSubmit,
      register,
      reset,
      watch,
      setValue,
      formState: { errors, dirtyFields }
    } = useForm({
      mode: 'onChange',
      reValidateMode: 'onChange',
      criteriaMode: 'all'
    });

    const [requireRebootModal, setRequireRebootModal] = useState(false);
    const [configStatus, setConfigStatus] = useState(
      new Map<string, ConfigStatus>()
    );

    const onSubmit = async (data: any) => {
      const statusPromises = Object.keys(dirtyFields)
        .map((key) => {
          return chargingStationConfigurationsStore.updateOrCreateConfiguration(
            key,
            data[key]
          ) as Promise<{ key: string; status: string }>;
        })
        .filter((status) => !!status) as Promise<{
          key: string;
          status: string;
        }>[];
      const statuses = await Promise.all(statusPromises);

      statuses.forEach((status) => {
        if (status) {
          setConfigStatus((s) => {
            s.set(status.key, {
              key: status.key,
              status: status.status as ChargingStationConfigurationStatus
            });
            return s;
          });
        }
      });

      if (
        Array.from(configStatus.values())
          .map((result) => result.status)
          .some(
            (status) =>
              status === ChargingStationConfigurationStatus.reboot_required
          )
      ) {
        setRequireRebootModal(true);
      }

      reset(data);
    };

    useEffect(() => {
      chargingStationConfigurationsStore.getSupportedDestinationCsmsDict();
    }, [chargingStationConfigurationsStore]);

    return (
      <Card className={globalStyles.container}>
        <ChargingStationStatusHeader />
        <div className={styles.configurationEditIcon}>
          {edit && (
            <Button
              disabled={!Object.keys(dirtyFields).length}
              size="medium"
              onClick={handleSubmit(onSubmit)}
            >
              Update
            </Button>
          )}
          <IconButton onClick={() => setEdit(!edit)} aria-label="settings">
            <EditIcon color="primary" />
          </IconButton>
        </div>

        <CardContent className={styles.configurationsCard}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {chargingStationConfigurationsStore.isLoadingConfigurationAndSchema ? (
              <Loader />
            ) : (
              Object.keys(
                chargingStationConfigurationsStore?.defaultConfigurationForCurrentChargingStation
              )
                .sort((a, b) => (a > b ? 1 : -1))
                .map((item) =>
                  <FieldFactory
                    key={item}
                    disabled={!edit || defaultConfigurationForCurrentChargingStation?.[item].mutability === 'ReadOnly'}
                    currentConfiguration={
                      defaultConfigurationForCurrentChargingStation?.[item]
                        .mutability !== 'WriteOnly'
                        ? currentChargingStationConfigurationVariables?.get(
                          item
                        )
                        : {
                          ...currentChargingStationConfigurationVariables?.get(
                            item
                          ),
                          key: item,
                          value: undefined
                        }
                    }
                    schemaItem={
                      defaultConfigurationForCurrentChargingStation?.[item]
                    }
                    variableKey={item}
                    control={control}
                    errors={errors}
                    register={register}
                    watchedValue={watch(item)}
                    options={item === GARO_CSMS_URL_KEY ? Array.from(chargingStationConfigurationsStore.supportedDestinationCsmsDict.values()).map(item => item.endpoint.secured) : undefined}
                    resetValue={() => {
                      setEdit(true);
                      setValue(
                        item,
                        defaultConfigurationForCurrentChargingStation[item]
                          ?.properties?.value.default,
                        { shouldDirty: true }
                      );
                    }}
                  />
                )
            )}
          </form>
        </CardContent>

        <Snackbar
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          open={requireRebootModal}
          onClose={() => null}
        >
          <Card style={{ display: 'flex' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent style={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                  Reboot required
                </Typography>
              </CardContent>
              <ButtonGroup style={{ display: 'flex' }} variant="outlined">
                <Button
                  onClick={() => {
                    setRequireRebootModal(false);
                    restart();
                  }}
                  style={{ flexGrow: 1 }}
                >
                  Restart software
                </Button>
                <Button
                  onClick={() => {
                    setRequireRebootModal(false);
                    reboot();
                  }}
                  style={{ flexGrow: 1 }}
                >
                  Reboot device
                </Button>
              </ButtonGroup>
            </Box>
          </Card>
        </Snackbar>
      </Card>
    );
  }
);
