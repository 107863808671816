
import {
  Box,
  Card,
  CardContent,
  Tab,
  Tabs
} from '@mui/material';

import React, { useState } from 'react';
import { ChargingStationGeneralDetails } from './charging-station-general-details';
import { ChargingStationStatusHeader } from './charging-station-status-header';

import {
  AvailableFirmware,
  BlocklistItem,
  BlockStatus,
  ChargingStation,
  ChargingStationReservation,
  ChargingStationStatus as ChargingStationDeviceStatus,
  OcppEvent,
  Transaction
} from 'src/store/model';

import { ChargingStationStatus } from './charging-station-status';
import { ChargingStationTransactions } from './charging-station-transactions';
import { ChargingStationMonitoring } from './charging-station-monitoring';
import { TabPanel } from 'src/components';
import globalStyles from '../../../../global-styles.module.scss';
import { CharginStationSchedule } from './charging-station-schedules';
import { ChargingStationSettings } from './charging-station-settings';

type Props = {
  chargingStation: ChargingStation | undefined | null;
  chargingStationStatus: ChargingStationDeviceStatus | null | undefined;
  occpEvents: OcppEvent[];
  transactions: Transaction[];
  locationName: string;
  officialFirmwares: AvailableFirmware[];
  unofficialFirmwares: AvailableFirmware[];
  blocklistItem: BlocklistItem;
  reservations: ChargingStationReservation[];
  profiles: any[];
  blockList: (blockStatus: BlockStatus) => void;
  flashLed: () => void;
  changeFirmware: (update_type: string, location: string, signing_certificate: string, signature: string) => void;
  clearReservations: () => void;
};

export const ChargingStationMetaData = ({
  chargingStation,
  chargingStationStatus,
  occpEvents,
  transactions,
  officialFirmwares,
  unofficialFirmwares,
  blocklistItem,
  reservations,
  profiles,
  blockList,
  flashLed,
  changeFirmware,
  clearReservations,
}: Props) => {

  const handleTabChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  };

  const [value, setValue] = useState(0);

  return (
    <Card className={globalStyles.container}>
      <ChargingStationStatusHeader />
      <CardContent style={{ minHeight: 650 }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex'
          }}
        >
          <Tabs
            orientation="vertical"
            style={{ width: '15rem' }}
            value={value}
            onChange={handleTabChange}
          >
            <Tab label="General" {...a11yProps(0)} />
            <Tab label="Status" {...a11yProps(1)} />
            <Tab label="Transactions" {...a11yProps(2)} />
            <Tab label="Monitoring" {...a11yProps(3)} />
            <Tab label="Schedules" {...a11yProps(4)} />
            <Tab label="Settings" {...a11yProps(5)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <ChargingStationGeneralDetails
              chargingStation={chargingStation}
              blockList={blockList}
              blocklistItem={blocklistItem}
              chargingStationStatus={chargingStationStatus}
              officialFirmwares={officialFirmwares}
              unofficialFirmwares={unofficialFirmwares}
              flashLed={flashLed}
              changeFirmware={changeFirmware}
              reservations={reservations}
              clearReservations={clearReservations}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ChargingStationStatus events={occpEvents} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ChargingStationTransactions transactions={transactions} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ChargingStationMonitoring />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <CharginStationSchedule profiles={profiles} isViewer={true}/>
            <CharginStationSchedule profiles={profiles} isViewer={false}/>
          </TabPanel>
          <TabPanel value={value} index={5}>
            <ChargingStationSettings />
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );
};
