import { observer } from 'mobx-react-lite';
import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from 'src/store';
import { ChargingStationConfiguration } from './components/charging-station-configuration';
import { ChargingStationLogFiles } from './components/charging-station-log-files';
import { ChargingStationMetaData } from './components/charging-station-meta-data';
import { useCurrentChargingStation, useInterval } from 'src/hooks';
import globalStyles from '../../../global-styles.module.scss';
import { DetailsTabs } from './components/details-tabs';
import { TabPanel } from '../../../components/tab-panel';
import { ChargingStationAuditLog } from './components/charging-station-audit-log';
import { BlockStatus } from 'src/store/model';
import { ChargingStationCommands } from './components/charging-station-commands';

export const ChargingStationDetails = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [value, setValue] = useState(0);

  const {
    chargingStationStore,
    locationStore,
    installationStore,
    chargingStationConfigurationsStore,
    actionStore,
  } = useStores();

  useCurrentChargingStation(
    chargingStationStore,
    chargingStationConfigurationsStore,
    installationStore,
    locationStore,
  );

  useInterval(() => {
    chargingStationStore.getChargingStation(id ?? '');
    chargingStationStore.getCurrentChargingStationSettings();
    chargingStationStore.getChargingStationConnectorStatus(id ?? '');
  }, 5000);

  const clearReservations = useCallback(
    () => {
      const promises = chargingStationStore.currentChargingStationReservations.map(
        (reservation) => reservation.id,
      ).map((id) => chargingStationStore.deleteChargingStationReservation(
        id
      ));

      Promise.all(promises);
    },
    [chargingStationStore],
  )

  return (
    <div className={globalStyles.container}>
      <DetailsTabs setValue={setValue} />
      <TabPanel value={value} index={0}>
        <ChargingStationMetaData
          chargingStation={chargingStationStore.currentChargingStation}
          officialFirmwares={installationStore.officialFirmwares}
          unofficialFirmwares={installationStore.unofficialFirmwares}
          chargingStationStatus={
            chargingStationStore.currentChargingStationStatus
          }
          occpEvents={chargingStationStore.currentChargingStationOccpLog}
          transactions={chargingStationStore.currentChargingStationTransactions}
          locationName={
            locationStore.locations.find(
              (loc) =>
                loc.id ===
                chargingStationStore.currentChargingStation?.location_id,
            )?.name || ''
          }
          blockList={(blockStatus: BlockStatus) => chargingStationStore.updateBlocklistItem(chargingStationStore.currentChargingStation?.charging_unit?.serial_number ?? '', blockStatus)}
          blocklistItem={chargingStationStore.blockListItems.get(chargingStationStore.currentChargingStation?.charging_unit?.serial_number ?? '') ?? { id: '', status: BlockStatus.Unblocked }}
          flashLed={async () =>
            await actionStore.changeConfiguration(id ?? '', {
              configuration_variables: [
                { key: 'GaroLightIndication', value: 'Identify' },
              ],
            })
          }
          changeFirmware={(
            update_type: string,
            location: string,
            signing_certificate: string,
            signature: string) => actionStore.changeFirmware(update_type, location, signing_certificate, signature
          )}
          clearReservations={clearReservations}
          reservations={chargingStationStore.currentChargingStationReservations}
          profiles={chargingStationStore.currentChargingStationProfiles}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChargingStationConfiguration
          chargingStationName={
            chargingStationStore.currentChargingStation?.name ?? ''
          }
          locationName={
            chargingStationStore.currentChargingStationLocationName ?? ''
          }
          restart={() => actionStore.resetDevice(id ?? '')}
          reboot={() => actionStore.rebootDevice(id ?? '')}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ChargingStationLogFiles></ChargingStationLogFiles>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ChargingStationAuditLog></ChargingStationAuditLog>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ChargingStationCommands />
      </TabPanel>
    </div>
  );
});
