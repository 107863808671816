import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
  FormControlLabel,
  Box
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from 'src/store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  AvailableFirmware,
  BlocklistItem,
  BlockStatus,
  ChargingStation,
  ChargingStationReservation,
  ChargingStationStatus,
} from 'src/store/model';
import styles from './charging-station-general-details.module.scss';
import * as dateFns from 'date-fns';
import { DropZone } from './drop-zone';

type Props = {
  chargingStation: ChargingStation | undefined | null;
  chargingStationStatus: ChargingStationStatus | null | undefined;
  officialFirmwares: AvailableFirmware[];
  unofficialFirmwares: AvailableFirmware[];
  blocklistItem: BlocklistItem;
  reservations: ChargingStationReservation[];
  blockList: (blockStatus: BlockStatus) => void;
  flashLed: () => void;
  changeFirmware: (update_type: string, location: string, signing_certificate: string, signature: string) => void;
  clearReservations: () => void;
};

export const ChargingStationGeneralDetails = ({
  chargingStation,
  chargingStationStatus,
  officialFirmwares,
  unofficialFirmwares,
  blocklistItem,
  reservations,
  blockList,
  flashLed,
  changeFirmware,
  clearReservations,
}: Props) => {

  const [selectedOfficialFirmware, setSelectedOfficialFirmware] = useState('');
  const [selectedUnofficialFirmware, setSelectedUnofficialFirmware] = useState('');
  const [customFirmwareUrl, setCustomFirmwareUrl] = useState('');
  const [customFirmwareCertificate, setCustomFirmwareCertificate] = useState('');
  const [customFirmwareSignature, setCustomFirmwareSignature] = useState('');

  const [actionRequestSuccess, showActionRequestSuccess] = useState(false);
  const [actionRequestFail, showActionRequestFail] = useState(false);
  const [actionRequestType, setActionRequestType] = useState('');
  const [signedFirmwareUpdate, setSignedFirmwareUpdate] = useState<boolean>(true);

  const navigate = useNavigate();

  const handleOfficialFWChange = (event: any) => {
    setSelectedOfficialFirmware(event.target.value);
  };

  const handleUnofficialFWChange = (event: any) => {
    setSelectedUnofficialFirmware(event.target.value);
  };

  const [openDeleteDialog, showDeleteDialog] = useState(false);

  const {
    actionStore,
    chargingStationStore,
    installationStore,
  } = useStores();

  const { t } = useTranslation();

  const checkFWVersion = (fw: string) => {
    const versionArray = fw.split('.');
    const compareVersionArray = "2.0.0".split('.');
    let isFWAfter = false;
    for (let i = 0; i < versionArray.length; i++) {
      if (parseInt(versionArray[i]) >= parseInt(compareVersionArray[i])) {
        isFWAfter = true; break;
      } else if (parseInt(versionArray[i]) < parseInt(compareVersionArray[i])) {
        isFWAfter = false; break;
      }
    }
    return isFWAfter;
  }

  const hasCertSign = (fw: AvailableFirmware) => {
    return fw.signing_certificate && fw.signature;
  }

  const supportedFirmwares = useMemo(() => {
    return officialFirmwares.filter((fw) => {
      return signedFirmwareUpdate ? checkFWVersion(fw.version ?? '') && hasCertSign(fw) : true;
    });
  }, [officialFirmwares, signedFirmwareUpdate]);

  const supportedUnofficialFirmwares = useMemo(() => {
    return unofficialFirmwares.filter((fw) => {
      return signedFirmwareUpdate ? checkFWVersion(fw.version ?? '') && hasCertSign(fw) : true;
    });
  }, [unofficialFirmwares, signedFirmwareUpdate]);

  useEffect(() => {
    setSignedFirmwareUpdate(checkFWVersion(chargingStation?.charging_unit?.firmware_version ?? ''));
  }, [chargingStation?.charging_unit?.firmware_version]);
  
  const handleActionRequestAlert = (state: string, action: string) => {
    setActionRequestType(action);
    if (state === 'success') {
      showActionRequestSuccess(true);
    } else {
      showActionRequestFail(true);
    }
  }

  const resetChargingStation = () => {
    const id = chargingStation?.id || '';
    actionStore.resetDevice(id).then(res => {
      handleActionRequestAlert(res, 'Restart software');
    })
  };

  const rebootChargingStation = () => {
    const id = chargingStation?.id || '';
    actionStore.rebootDevice(id).then(res => {
      handleActionRequestAlert(res, 'Reboot device');
    })
  };

  const confirmDeleteChargingStation = () => {
    showDeleteDialog(true);
  };

  const handleDelete = () => {
    if (!chargingStation) {
      return;
    }
    chargingStationStore.removeChargingStation(chargingStation).then(() => {
      showDeleteDialog(false);
      navigate('/service-tech/charging-stations');   
    });
  }

  const handleCancelDelete = () => {
    showDeleteDialog(false);
  }

  const getGroupKey = () => {
    const groupKey = chargingStationStore.groupKey;
    if (groupKey) {
      return groupKey;
    }
    return 'Loading...';
  }

  const handleSignedFirmwareUpdate = (e: any) => {
    setSignedFirmwareUpdate(e.target.checked);
  }

  const handleCustomFirmwareChange = (e: any): void => {
    setCustomFirmwareUrl(e.target.value);
  }

  const handleFileChosen = (file: any) => {
    console.log(file);
    const reader = new FileReader();

    reader.onload = (event) => {
      const pemFile = event.target?.result as string;

      if (pemFile) {
        const pemFileParts = pemFile.split('-----BEGIN CERTIFICATE-----');

        if (pemFileParts.length > 1) {
          const certificateParts = pemFileParts[1].split('-----END CERTIFICATE-----');
          setCustomFirmwareCertificate('-----BEGIN CERTIFICATE-----' + certificateParts[0] + '-----END CERTIFICATE-----');

          const signatureParts = certificateParts[1].split('-----BEGIN RSA SIGNATURE-----');
          const signature = signatureParts ? signatureParts[1].split('-----END RSA SIGNATURE-----') : '';
          setCustomFirmwareSignature(signature[0]);
        } else {
          console.log('The file does not contain a valid PEM certificate and signature.');
          setCustomFirmwareCertificate('The file does not contain a valid PEM certificate and signature.');
        }
      }
    };

    reader.readAsText(file);
  };

  const handleOfficialFWUpdate = () => {
    const fw = supportedFirmwares.find((fw) => fw.location === selectedOfficialFirmware);
    changeFirmware(signedFirmwareUpdate ? 'Secure' : 'Standard', selectedOfficialFirmware, fw?.signing_certificate ?? '', fw?.signature ?? '');
  };

  const handleUnofficialFWUpdate = () => {
    const fw = supportedUnofficialFirmwares.find((fw) => fw.location === selectedUnofficialFirmware);
    changeFirmware(signedFirmwareUpdate ? 'Secure' : 'Standard', selectedUnofficialFirmware, fw?.signing_certificate ?? '', fw?.signature ?? '');
  }

  return (
    <div className={styles.chargingStationGeneralDetails}>

      {actionRequestSuccess && <Alert variant="filled" severity="info">{t('Request recieved.')} <em>({actionRequestType})</em></Alert>}
      {actionRequestFail && <Alert variant="filled" severity="error">{t('Request error.')} <em>({actionRequestType})</em></Alert>}

      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          className={styles.field}
          id="standard-name"
          label={t('Name')}
          variant="standard"
          disabled={true}
          value={chargingStation?.name}
        />
      </div>
      <TextField
        label={t('Vendor')}
        defaultValue={chargingStation?.charging_unit?.vendor_name}
        variant="standard"
        disabled={true}
        className={styles.field}
      />

      <TextField
        label={t('Model')}
        defaultValue={chargingStation?.charging_unit?.model}
        variant="standard"
        disabled={true}
        className={styles.field}
      />
      <TextField
        label={t('Serial code')}
        defaultValue={chargingStation?.uid}
        variant="standard"
        disabled={true}
        className={styles.field}
      />
      <TextField
        label={t('CU number')}
        defaultValue={chargingStation?.charging_unit?.serial_number}
        variant="standard"
        disabled={true}
        className={styles.field}
      />
      <TextField
        label={t('Group key')}
        value={getGroupKey()}
        variant="standard"
        disabled={true}
        className={styles.field}
      />
      <TextField
        label={t('Last heartbeat')}
        value={dateFns.format(
            new Date(chargingStation?.status?.heartbeat_timestamp as any),
            'yyyy-MM-dd HH:mm:ss'
            )}
        variant="standard"
        disabled={true}
        className={styles.field}
      />
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControl style={{ width: 320 }} variant="standard">
          <InputLabel id="select-label">{t('Theft block status:')}</InputLabel>
          <Select
            labelId="select-label"
            id="select"
            fullWidth={true}
            value={blocklistItem.status ?? BlockStatus.Unblocked}
            onChange={(s) => blockList(s.target.value as BlockStatus)}>
            {Object.values(BlockStatus).map((status) => (
              <MenuItem key={status} value={status ?? ''}>
                {`${status}`}
              </MenuItem>
            ))}
          </Select>
          {blocklistItem.blocklisted_since && (
            <Typography>
              Since:{' '}
              {dateFns.format(
                new Date(blocklistItem.blocklisted_since),
                'yyyy-MM-dd HH:mm',
              )}
            </Typography>
          )}
        </FormControl>
      </div>
      <TextField
        label={t('Current firmware')}
        value={chargingStation?.charging_unit?.firmware_version}
        variant="standard"
        disabled={true}
        className={styles.field}
      />
      <FormControlLabel control={
        <Checkbox 
          checked={signedFirmwareUpdate}
          onChange={handleSignedFirmwareUpdate}/>
        } label={t('Signed Firmware Update')} />
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControl style={{ width: 320 }} variant="standard">
          <InputLabel id="select-label">
            {t('Official firmware version:')}
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            fullWidth={true}
            value={selectedOfficialFirmware}
            onChange={handleOfficialFWChange}>
            {supportedFirmwares.map((fw) => {
              return (
              <MenuItem key={fw.key} value={fw.location ?? ''}>
                {`${fw.model} ${fw.version}`}
              </MenuItem>
            )})}
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={!selectedOfficialFirmware}
          size="medium"
          onClick={handleOfficialFWUpdate}>
            {t('Change firmware')}
        </Button>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <FormControl style={{ width: 320 }} variant="standard">
          <InputLabel id="select-label">
            {t('Unofficial firmware version:')}
          </InputLabel>
          <Select
            labelId="select-label"
            id="select"
            fullWidth={true}
            value={selectedUnofficialFirmware}
            onChange={handleUnofficialFWChange}>
            {supportedUnofficialFirmwares.map((fw) => (
              <MenuItem key={fw.key} value={fw.location ?? ''}>
                {`${fw.model} ${fw.version}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={!selectedUnofficialFirmware}
          size="medium"
          onClick={handleUnofficialFWUpdate}>
            {t('Change firmware')}
        </Button>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          className={styles.field}
          id="standard-name"
          label={t('Custom firmware URL')}
          variant="standard"
          value={customFirmwareUrl}
          onChange={handleCustomFirmwareChange}
        />
        <Button
          style={{ marginLeft: '1rem' }}
          disabled={!customFirmwareUrl}
          size="medium"
          onClick={() => {
            changeFirmware(signedFirmwareUpdate ? 'Secure' : 'Standard', customFirmwareUrl, customFirmwareCertificate, customFirmwareSignature);
          }}>
          {t('Change firmware')}
        </Button>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          inputProps={{style: {fontSize: 8}}}
          className={styles.field}
          disabled={!signedFirmwareUpdate}
          multiline
          rows={4}
          id="standard-name"
          label={t('Certificate')}
          variant="standard"
          value={signedFirmwareUpdate ? customFirmwareCertificate : ''}
        />
        <Box style={{marginLeft: '1.5rem'}}>
          <DropZone onChange={(file: File) => handleFileChosen(file)} />
        </Box>
      </div>
      <div className={styles.chargingStationGeneralDetailsRow}>
        <TextField
          inputProps={{style: {fontSize: 7}}}
          className={styles.field}
          disabled={!signedFirmwareUpdate}
          multiline
          rows={4}
          id="standard-name"
          label={t('Signature')}
          variant="standard"
          value={signedFirmwareUpdate ? customFirmwareSignature : ''}
        />
      </div>
      <div style={{marginBottom: 20}}>Firmware update status: <b>{chargingStationStatus?.firmware_update}</b></div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          width: '100%',
        }}>
        <Button style={{ width: 200 }} size="medium" onClick={flashLed}>
          {t('Flash LED')}
        </Button>
        {reservations.length > 0 && (
          <Button
            style={{ width: 200 }}
            size="medium"
            color="primary"
            onClick={clearReservations}>
            {t('Remove all reservations')}
          </Button>
        )}

        <Button
          style={{ width: 200 }}
          size="medium"
          color="primary"
          onClick={resetChargingStation}>
          {t('Restart software')}
        </Button>

        <Button
          style={{ width: 200 }}
          size="medium"
          color="primary"
          onClick={rebootChargingStation}>
          {t('Reboot device')}
        </Button>
      </div>
      <Button
        style={{ width: 200 }}
        size="medium"
        color="warning"
        onClick={confirmDeleteChargingStation}>
        {t('Delete device')}
      </Button>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('Delete charging station: ') + chargingStation?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Do you really want to delete this device?')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete}>Cancel</Button>
          <Button onClick={handleDelete} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
};
