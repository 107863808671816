export default {
  translation: {
    'uk': 'Engelsk (Storbritannia)',
    'en': 'Engelsk (Storbritannia)',
    'fi': 'Suomi',
    'se': 'Svensk',
    'no': 'Norsk',
    'de': 'Tysk',
    'ie': 'Engelsk (IE)',
    'pl': 'Polsk',
    'Name': 'Navn',
    'Email': 'E-post',
    'Password': 'Passord',
    'Login': 'Logg inn',
    'Language same as region': 'Språk samme som region',
    'Rename charging station': 'Endre navn på ladestasjon',
    'Vendor': 'Leverandør',
    'Model': 'Modell',
    'Serial code': 'Serienummer',
    'Current firmware': 'Gjeldende fastvare',
    'Custom firmware URL': 'Tilpasset fastvare-URL',
    'Change firmware': 'Endre fastvare',
    'Remove all reservations': 'Fjern alle reservasjoner',
    'Reboot device': 'Start enheten på nytt',
    'Flash LED': 'Blink LED',
    'Select firmware version:': 'Velg fastvareversjon:',
    'Official firmware version:': 'Offisiell fastvareversjon:',
    'Unofficial firmware version:': 'Uoffisiell fastvareversjon:',
    'Documentation': 'Dokumentasjon',
    'Product information': 'Produktinformasjon',
    'Support': 'Kundestøtte',
    'Download GARO Connect': 'Last ned GARO Connect',
    'GET IT ON': 'HENT DEN PÅ',
    'Manual': 'Brukerveiledning',
    'Quick Guide': 'Hurtigguide',
    'Polemount manual': 'Brukerveiledning for stolpemontering',
    'Stand manual': 'Brukerveiledning for stativ',
    'Installation kit': 'Installasjonssett',
    'Using Proxy': 'Bruker Proxy',
    'Online': 'På nett',
    'Offline': 'Av nett',
    'Generating logfile': 'Genererer loggfil',
    'Generate logfile': 'Generer loggfil',
    'Currently this portal is for service technicians only.': 'For øyeblikket er denne portalen kun for service teknikere.',
    'Restart software': 'Start programvaren på nytt',
    'Theft block status:': 'Status for tyveriblokkering:',
    'Current schedule': 'Gjeldende plan',
    'Edited schedule': 'Redigert plan',
    'Monday': 'Mandag',
    'Tuesday': 'Tirsdag',
    'Wednesday': 'Onsdag',
    'Thursday': 'Torsdag',
    'Friday': 'Fredag',
    'Saturday': 'Lørdag',
    'Sunday': 'Søndag',
    'Weekly': 'Ukentlig',
    'One time': 'En gang',
    'Delete event': 'Slett hendelse',
    'Delete description': 'Vil du slette planleggingsarrangementet permanent?',
    'No': 'Nei',
    'Yes': 'Ja',
    'Start time': 'Starttid',
    'Stop time': 'Stopp tid',
    'Free of charge': 'Gratis',
    'Sync configuration always on boot': 'Synkroniser alltid konfigurasjon ved oppstart',
    'Ignore unknown keys on sync': 'Ignorer ukjente nøkler ved synkronisering',
    'Update firmware automatically': 'Oppdater fastvare automatisk',
    'Sync configuration as best effort': 'Synkroniser konfigurasjon som best innsats',
    'Store warning': 'Advarsel: Verdien vil ikke bli lagret i CSMS og kan derfor bli overskrevet ved neste omstart av enheten',
    'Store in CSMS': 'Lagre i CSMS',
    'Key': 'Nøkkel',
    'Value': 'Verdi',
    'Set Parameter Value': 'Sett parameterverdi',
    'Get Parameter Value': 'Hent parameterverdi',
    'Command': 'Kommando',
    'Add command': 'Legg til kommando',
    'Execute Command': 'Utfør kommando',
    'Received data': 'Mottatt data',
    'Snackbar copy message': 'Kopiert til utklippstavlen',
    'Tooltip copy message': 'Kopiert til utklippstavlen',
  },
};
