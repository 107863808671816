import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useStores } from 'src/store';
import { useCurrentChargingStation, useInterval } from 'src/hooks';
import { TabPanel } from '../../../components/tab-panel';
import { DetailsTabs } from './components/details-tabs';
import { Details } from './components/details';
import { Groups } from './components/groups';
import { Configurations } from './components/configurations';
import { Access } from './components/access';
import { Actions } from './components/actions';
import { DataTransfer } from 'src/components/data-transfer/data-transfer';
import { LocationStatusHeader } from './components/location-status-header';
import { Card } from '@mui/material';
import globalStyles from '../../../global-styles.module.scss';

export const Locations = observer(() => {
  const { id } = useParams<{ id: string }>();
  const [value, setValue] = useState(0);

  const {
    chargingStationStore,
    locationStore,
    installationStore,
    chargingStationConfigurationsStore,
  } = useStores();

  useCurrentChargingStation(
    chargingStationStore,
    chargingStationConfigurationsStore,
    installationStore,
    locationStore,
  );

  useEffect(() => {
    chargingStationStore.getChargingStations(
      'location_id',
      id,
      250,
    );
  },[chargingStationStore, id]);

  useInterval(() => {
    // chargingStationStore.getChargingStation(id ?? '');
  }, 5000);

  return (
    <>
      <DetailsTabs setValue={(v) => {
        // Temporarily disable the "Access" and "Actions" tab
        if (v === 3 || v === 4) return;
        setValue(v);
      }} />
      <TabPanel value={value} index={0}>
        <Details chargingStations={chargingStationStore.chargingStationSearchResult} locationId={id}/>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Groups chargingStations={chargingStationStore.chargingStationSearchResult}/>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Configurations chargingStations={chargingStationStore.chargingStationSearchResult}/>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Access />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Actions chargingStations={chargingStationStore.chargingStationSearchResult}/>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Card className={globalStyles.container} style={{ paddingBottom: 25 }}>
          <LocationStatusHeader />
          <DataTransfer chargingStations={chargingStationStore.chargingStationSearchResult} />
        </Card>
      </TabPanel>
    </>
  );
});
