import { flow, getEnv, getParent, types } from 'mobx-state-tree';

import { ActionsApi } from '../api';
import { BatchAction, ChargingStationChangeConfigurations, OCPPChargingProfile } from './model';
import { RootModel } from './RootStore';

export const ActionStore = types
  .model({})
  .views((self) => {
    return {
      get api(): ActionsApi {
        return getEnv(self).actionApi;
      },
      get currentChargingStationId(): string | null | undefined {
        return getParent<typeof RootModel>(self).chargingStationStore
          .currentChargingStation?.id;
      },
      get latestFirmwareVersionKey(): string | null | undefined {
        return getParent<typeof RootModel>(self).installationStore
          .latestFirmwareVersionKey;
      },
      get isChargingStationOnline(): (chargingStationId: string) => boolean {
        return getParent<typeof RootModel>(self).chargingStationStore
          .isChargingStationOnline;
      },
      get isCurrentChargingStationAvailable(): boolean {
        return getParent<typeof RootModel>(self).chargingStationStore
          .isCurrentChargingStationAvailable;
      }
    };
  })
  .actions((self) => {
    const changeFirmware = flow(function* (update_type: string, location: string, signing_certificate: string, signature: string) {
      if (self.currentChargingStationId && location) {
        console.log('changeFirmware', self.currentChargingStationId, location, signing_certificate, signature, self.latestFirmwareVersionKey, update_type);
        const { data, error } = yield self.api.updateFirmware(
          self.currentChargingStationId,
          location,
          signing_certificate,
          signature,
          update_type,
        );

        if (!error && data?.status) {
          return data.status;
        }
      }
    });

    const batchAction = flow(function* (actions: BatchAction[]) {
      if (actions.length > 0) {
        const { data, error } = yield self.api.batchAction(actions);

        if (!error && data?.status) {
          return data.status;
        }
      }
    });

    const getConfiguration = flow(function* (
      chargingStationId: string,
      key: string[],
    ) {
      console.log('getConfiguration s', chargingStationId, key);
      return yield self.api.getConfiguration(
        chargingStationId,
        key
      );
    });

    const changeConfiguration = (
      chargingStationId: string,
      changeConfigurations: ChargingStationChangeConfigurations
    ) => {
      if (self.isChargingStationOnline(chargingStationId)) {
        return self.api.changeConfiguration(
          chargingStationId,
          changeConfigurations
        );
      } else {
        return Promise.resolve({
          data: {
            status: 'Offline'
          }
        });
      }
    };

    const resetDevice = flow(function* (chargingStationId: string) {
      const { error } = yield self.api.resetSoft(chargingStationId);

      if (!error) {
        return 'success';
      }

      return 'error';
      /*
      if (!error && data?.status) {
        return data.status;
      }
      */
    });

    const rebootDevice = flow(function* (chargingStationId: string) {
      const { error } = yield self.api.resetHard(chargingStationId);

      if (!error) {
        return 'success';
      }

      return 'error';
      /*
      if (!error && data?.status) {
        return data.status;
      }
      */
    });

    const generateChargingStationLogFile = flow(function* (
      chargingStationId: string,
      log_type = 'DiagnosticsLog'
    ) {
      if (self.isChargingStationOnline(chargingStationId)) {
        const { error } = yield self.api.generateChargingStationLogFile(
          chargingStationId,
          log_type,
        );

        if (!error) {
          return 'success';
        }

        return 'error';
      }
    });

    const clearChargingProfile = flow(function* (
      charginStationId: string,
      charging_profile_id: number,
      connector_id: number,
    ) {
      if (self.isChargingStationOnline(charginStationId)) {
        return yield self.api.clearChargingProfile(
          charginStationId,
          charging_profile_id,
          connector_id,
        );
      }
    });

    const setChargingProfile = flow(function* (
      charginStationId: string,
      connectorId: number,
      occpChargingProfile: OCPPChargingProfile,
    ) {
      if (self.isChargingStationOnline(charginStationId)) {
        return yield self.api.setChargingProfile(
          charginStationId,
          connectorId,
          occpChargingProfile,
        );
      }
    });

    const dataTransfer = flow(function* (
      chargingStationId: string,
      data = '',
      messageId: string,
    ) {
      if (self.isChargingStationOnline(chargingStationId)) {
        const res = yield self.api.dataTransfer(
          chargingStationId,
          data,
          messageId,
        );
        res.data = { ...res.data, charging_station_id: chargingStationId};
        return res;
      } else {
        return Promise.resolve({
          data: {
            charging_station_id: chargingStationId,
            status: 'Offline',
            data: '',
          }
        });
      }
    });

    return {
      getConfiguration,
      changeConfiguration,
      changeFirmware,
      resetDevice,
      batchAction,
      generateChargingStationLogFile,
      rebootDevice,
      clearChargingProfile,
      setChargingProfile,
      dataTransfer
    };
  });
