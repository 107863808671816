import { Card } from '@mui/material';
import React from 'react';
import { theme } from 'src/theme';
import styles from './documentation.module.scss';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n/i18n';

export const Documentation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const getLanguageCodePro = () => {
    const language = i18n.language;

    if (['en', 'uk', 'ie', 'no'].includes(language)) {
      return 'en';
    }
    return language;
  };

  const getLanguageCodeCompact = () => {
    const language = i18n.language;

    if (!['fi', 'se', 'en', 'pl'].includes(language)) {
      return 'en';
    }
    return language;
  };

  const getQuickGuideProVersion = () => {
    const language = i18n.language;

    if (['en', 'uk', 'ie'].includes(language)) {
      console.log('v1_3');
      return 'v1_3';
    }
    return 'v1_0';
  };


  const getQuickGuideCompactVersion = () => {
    const language = i18n.language;
    console.log('language', language)


    if (['en', 'uk', 'ie'].includes(language)) {
      return 'v1_3';
    }
    return 'v1_1';
  };


  return (
    <Card
      style={{ borderRadius: 0, backgroundColor: theme.palette.secondary.dark }}
      className={styles.documentationCard}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#fff',
          height: 120,
          paddingLeft: 40,
          paddingRight: 40,
        }}>
        <button className={styles.headerButton} onClick={() => navigate('/landing')}>
          <ArrowBackIosIcon />
        </button>
        <h1 className={styles.headerTitle}>{t('Documentation')}</h1>
      </div>

      <section className={styles.menuContainer}>
        <div className={styles.itemsContainer}>
          <h1>GARO Entity Pro</h1>
          <DocumentationItem
            title={t('Manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/${getLanguageCodePro()}/${getLanguageCodePro().toUpperCase()}_Manual_QG_Entity_Pro_InstallOperate_v1_3_4.pdf`}
          />
          <DocumentationItem
            title={t('Quick Guide')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380274_QG_Entity_Pro_InstallOperate_${getQuickGuideProVersion()}.pdf`}
          />
          <DocumentationItem
            title={t('Polemount manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380280_QG_Polemount_Pro_v1_0.pdf`}
          />
          <DocumentationItem
            title={t('Stand manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380281_QG_Stand_Pro_v1_0.pdf`}
          />
          <DocumentationItem
            title={t('Installation kit')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380284_QG_InstallationKit_v1_0.pdf`}
          />
          <DocumentationItem
            title={t('CE Declaration GARO Entity Pro')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/other/CE_Declaration_Entity_Pro.pdf`}
          />
        </div>

        <div className={styles.itemsContainer}>
          <h1>GARO Entity Compact</h1>
          <DocumentationItem
            title={t('Manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/${getLanguageCodeCompact()}/${getLanguageCodeCompact().toUpperCase()}_Manual_QG_Compact_InstallOperate_v1_3.pdf`}
          />
          <DocumentationItem
            title={t('Quick Guide')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380278_QG_Compact_InstallOperate_${getQuickGuideCompactVersion()}.pdf`}
          />
          <DocumentationItem
            title={t('Polemount manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380282_QG_Polemount_Compact_v1_1.pdf`}
          />
          <DocumentationItem
            title={t('Stand manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380303_QG_Stand_Compact_v1_1.pdf`}
          />
          <DocumentationItem
            title={t('CE Declaration GARO Entity Compact')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/other/CE_Declaration_Entity_Compact.pdf`}
          />

        </div>

        <div className={styles.itemsContainer}>
          <h1>GARO Entity Balance Basic</h1>
          <DocumentationItem
            title={t('Quick Guide')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380276_QG_LI_Basic_v1_1.pdf`}
          />
        </div>

        <div className={styles.itemsContainer}>
          <h1>GARO Entity Balance Advanced</h1>
          <DocumentationItem
            title={t('Quick Guide')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_380277_QG_LI_Advanced_v1_1.pdf`}
          />
        </div>

        <div className={styles.itemsContainer}>
          <h1>Other</h1>

          <DocumentationItem
            title={t('CE Declaration GARO Entity Balance')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/other/CE_Declaration_Entity_Balance.pdf`}
          />
          <DocumentationItem
            title={t('CE Declaration GARO Entity Pro Stand')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/other/CE_Declaration_Entity_Pro_Stand.pdf`}
          />
          <DocumentationItem
            title={t('GARO source code')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/other/GARO_source_code.pdf`}
          />
          <DocumentationItem
            title={t('GARO Connect Report tool manual')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_Manual_GARO_Connect_Report_tool_v1_0.pdf`}
          />
          <DocumentationItem
            title={t('GARO Connect Price Adaptive Charging (PAC)')}
            downloadUrl={`${process.env.REACT_APP_TECHNICAL_DOCUMENTATION_BASE_URL}/quick-guides/v1/en/EN_Manual_GARO_Connect_Price_Adaptive_Charging_PAC_v1_0.pdf`}
          />
        </div>
      </section>
    </Card>
  );
};

const DocumentationItem = ({
  title,
  downloadUrl,
  disabled,
}: {
  title: string;
  downloadUrl: string;
  disabled?: boolean;
}) => {
  return (
    <a
      href={disabled ? undefined : downloadUrl}
      rel="noreferrer"
      className={styles.menuItem}
      style={{ opacity: disabled ? 0.5 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}
      target="_blank">
      <img
        className={styles.menuIcon}
        src={require(`../../../images/help.png`)}></img>

      <span className={styles.itemButtonText}>{title}</span>

      <img
        className={`${styles.menuIcon} ${styles.downloadIcon}`}
        src={require(`../../../images/LandingPage_external_link.png`)}></img>
    </a>
  );
};
