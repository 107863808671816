import {
  Avatar,
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
  } from '@mui/material';
  import React from 'react';
  import { ChargingStationConnectionStatus, ChargingStationSearchItem, ConnectorStatus } from 'src/store/model';
  import { useStores } from 'src/store';
import { useTranslation } from 'react-i18next';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudIcon from '@mui/icons-material/Cloud';
import { BasicPopover } from 'src/components';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { useNavigate } from 'react-router-dom';
  
  type Props = {
    chargingStations: ChargingStationSearchItem[];
  };

  export const DetailsUnits = ({ chargingStations }: Props) => {
    const { chargingStationStore } = useStores();
    const navigate = useNavigate();
    
    const gotoChargingStationView = (id: string) => {
      chargingStationStore.setCurrentChargingStation(id);
      navigate(`/service-tech/charging-stations/${id}`);
    };
    
    return (
      <Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }}size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Device</TableCell>
                <TableCell align="left">FW</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="left">Serial Number</TableCell>
                <TableCell align="center">Availability</TableCell>
                <TableCell align="center">Connection</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {chargingStationStore.chargingStationSearchResult.map((row) => {
              return (
                <TableRow
                  key={row.charging_station?.id}
                >
                  <TableCell component="th" scope="row">
                    <Avatar
                      aria-label="recipe"
                      className={'avatar'}
                      src={
                        row?.charging_station?.load_interface ? require('../../../../images/load_interface.png') : require('../../../../images/charging_station.png')
                      }
                    ></Avatar>
                  </TableCell>
                  <TableCell align="left">{row.charging_station?.charging_unit?.firmware_version}</TableCell>
                  <TableCell align="center">
                    <Button
                      style={{ width: 100, textTransform: 'none' }}
                      size="small"
                      color="primary"
                      onClick={() => gotoChargingStationView(row.charging_station?.id ?? '')}
                    >
                      {row?.charging_station?.name}
                    </Button>
                  </TableCell>
                  <TableCell align="left">{row.charging_station?.charging_unit?.serial_number}</TableCell>
                  <TableCell align="center">
                    {row?.charging_station?.status?.connection === ChargingStationConnectionStatus.connected ? (
                      row?.charging_station?.connectors_statuses?.find(c => c.connector_id === 0)?.status ===
                      ConnectorStatus.Available ? (
                        <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: 'green', marginLeft: 'auto', marginRight: 'auto'}} />
                      ) : (
                        <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: 'red', marginLeft: 'auto', marginRight: 'auto'}} />
                      )
                    ) : (
                      <div style={{width: 10, height: 10, borderRadius: 5, backgroundColor: 'grey', marginLeft: 'auto', marginRight: 'auto'}} />
                    )}
                  </TableCell>
                  <ConnectionDetails online={row?.charging_station?.status?.connection ===
                    ChargingStationConnectionStatus.connected}
                    usingProxy={!!row?.charging_station?.status?.using_proxy} />
                </TableRow>
              );
            })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  function ConnectionDetails({
    online,
    usingProxy
    }: {
      online: boolean;
      usingProxy: boolean;
    }) {
    
    
      const { t } = useTranslation();
    
      return (
        <TableCell align="center">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <BasicPopover
              buttonContent={
                online ? (
                  usingProxy ? (<DeviceHubIcon color="action" />) :
                  (<CloudIcon color="action" />)
                ) : (
                  <CloudOffIcon color="action" />
                )
              }
              popOverContent={ (
                <Typography
                  style={{
                    margin: '5px 20px'
                  }}
                >
                  {
                  usingProxy ? t('Using Proxy') : (online ? t('Online') : t('Offline'))
                  }
                </Typography>
              )}
            />
          </div>
      </TableCell>
      );
    }