
import {
  Box,
  Card,
  CardContent,
  Tab,
  Tabs
} from '@mui/material';
import React, { useState } from 'react';
import { ChargingStationSearchItem } from 'src/store/model';
import { TabPanel } from 'src/components';
import { DetailsUnits } from './details-units';
import { LocationStatusHeader } from './location-status-header';
import globalStyles from '../../../../global-styles.module.scss';
type Props = {
  chargingStations: ChargingStationSearchItem[];
  locationId: string | undefined;
};

export const Details = ({ chargingStations }: Props) => {
  const handleTabChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`
    };
  };

  const [value, setValue] = useState(0);

  return (
    <Card className={globalStyles.container}>
      <LocationStatusHeader />
      <CardContent style={{ minHeight: 650 }}>
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: 'background.paper',
            display: 'flex'
          }}
        >
          <Tabs
            orientation="vertical"
            style={{ width: '15rem' }}
            value={value}
            onChange={handleTabChange}
          >
            <Tab label="Units" {...a11yProps(0)} />
            <Tab label="Info" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <DetailsUnits chargingStations={chargingStations}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <DetailsInfo /> */}
          </TabPanel>
        </Box>
      </CardContent>
    </Card>
  );
};
